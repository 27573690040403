/* Estils generals */
@font-face {
   font-family: "RobotoText";
   src: url("../fonts/Roboto/Roboto-Light.ttf");
}

@font-face {
   font-family: "RobotoTitols";
   src: url("../fonts/Roboto/Roboto-Bold.ttf");
}

@font-face {
   font-family: "RobotoMedium";
   src: url("../fonts/Roboto/Roboto-Medium.ttf");
}

.botoNormal-notes{
   display: flex;
   justify-content: center;
   align-items: center;
}

.botoNormal-notes svg{
   margin-right: 1vh;
}


.botoNormal svg, .botoN-curt svg{
   margin-right: 1vh;
}

@media screen and (max-width: 575px) {
   .botoNormal {
      display: none !important;
   }

   .botoN-curt {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      margin-inline: 1vh;
      width: 100%;
   }

   .header-calendari{
      flex-direction: column !important;
   }

   #botons-calendari{
      margin-top: 1vh;
      justify-content: center !important;
      align-items: center !important;
   }
}

@media screen and (min-width: 576px) {
   .botoNormal {
      display: flex !important;
      justify-content: center;
      align-items: center;
      margin-left: 1vh;
   }

   .botoN-curt {
      display: none !important;
   }
}

.header-calendari{
   display: flex;
   width: 100%;
   flex-direction: row;
   justify-content: center;
   align-items: center;
}

#titol-calendari{
   display: flex;
   justify-content: left;
   align-items: center;
   width: 100%;
}

#botons-calendari{
   display: flex;
   justify-content: right;
   align-items: center;
   width: inherit;
}

.container,
nav,
footer {
   font-family: "RobotoText", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
   font-family: "RobotoTitols", sans-serif;
}

main {
   background-color: white;
   margin-top: 5vh;
   margin-bottom: 5vh;
   padding: 5vh;
   border-radius: 20px;
   box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
   min-height: 100vh;
}

@media screen and (max-width: 575px) {
   main {
      padding: 2vh;
   }
}

@media screen and (min-width: 768px) {
   .botons-edicio button {
      font-size: 13pt;
      width: 20vh;
      margin-top: 2vh;
   }

   .botons-edicio {
      width: 100%;
   }
}

@media screen and (max-width: 767px) {
   /* Estils mantenimient taules responsive */
   .botons-edicio {
      flex-direction: column;
      margin-top: 5vh;
   }

   .botons-edicio button {
      width: 100%;
      margin-bottom: 2vh;
   }

   .ag-theme-quartz {
      height: 100vh;
   }

   .ag-paging-panel {
      display: contents !important;
      text-align: center !important;
   }

   span.ag-paging-page-summary-panel {
      margin: auto;
      margin-top: 2vh;
      margin-bottom: 2vh;
   }

   span.ag-paging-row-summary-panel {
      margin: auto;
      margin-top: 2vh;
   }

   span.ag-paging-page-size {
      margin-top: 2vh;
   }
}

.login-main {
   background-color: unset !important;
   box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
   margin-top: 1vh;
   margin-bottom: 1vh;
   padding: 1vh;
}

.container {
   width: 100%;
}

.row.row-cols-md-9 {
   align-items: center;
}

#desplegable::after {
   display: none;
}

.benvinguda-usuari {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
}

.benvinguda-usuari svg {
   margin-inline: 1vh;
}

.nav-link.active,
.active-dropdown .dropdown-toggle {
   color: #c42020 !important;
   font-family: "RobotoMedium", sans-serif;
}

body {
   background-image: url(../images/fondoapp6.webp);
   background-size: cover;
   background-repeat: no-repeat;
   background-attachment: fixed;
}

.btn-warning:hover {
   background-color: #ffd75d !important;
}

.btn-info:hover {
   background-color: #73ddf3 !important;
}

/* Estils modal turnos */

.info-turnos {
   padding: 2vh;
   background-color: #e3e3e3;
   border: 1px solid black;
   border-radius: 20px;
   justify-content: center;
   display: flex;
   flex-direction: column;
   align-items: center;
}

.modal-header {
   text-align: center !important;
}

.modal-title.h4 {
   width: 100%;
   font-family: "RobotoTitols", sans-serif;
}

.modal-content {
   font-family: "RobotoText", sans-serif;
}

/* Estils companys del turno */

.grup-companys-turnos {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin-bottom: 2vh;
   width: 100%;
}

.companys-turnos td {
   border: 1px solid black;
   padding: 1vh;
   text-align: center;
}

.companys-turnos{
   width: 100%;
}

.companys-turnos th{
   background-color: #c42020;
   color: white;
   font-family: 'RobotoTitols', sans-serif;
   font-size: 14pt;
}

.relevo {
   background-color: #3b82f6 !important;
   color: white;
}

.formacion {
   background-color: rgb(135 36 251) !important;
   color: white;
}

.cambio {
   background-color: yellow !important;
   color: rgb(0, 0, 0);
}

.no-viene{
   background-color: #7e7e7e !important;
   color: rgb(0, 0, 0);
}

.companys-turnos .company{
   background-color: #ffffff;
}

.modal-baja {
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #dc2626;
   color: white;
   padding: 10px;
   text-align: center;
   border: 1px solid black;
   border-radius: 15px;
}

.modal-formacion {
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: rgb(135 36 251);
   color: white;
   padding: 10px;
   text-align: center;
   border: 1px solid black;
   border-radius: 15px;
}

.modal-ap {
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #ff6d1f;
   color: white;
   padding: 10px;
   text-align: center;
   border: 1px solid black;
   border-radius: 15px;
}

.modal-turno {
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #3b82f6;
   color: white;
   padding: 10px;
   text-align: center;
   border: 1px solid black;
   border-radius: 15px;
}

.modal-vacacions {
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #10b981;
   color: white;
   padding: 10px;
   text-align: center;
   border: 1px solid black;
   border-radius: 15px;
}

.modal-titol-turnos {
   font-size: 15pt;
}

.info-turno-companys {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   padding: 15px;
   text-align: center;
}

/* Estils polítiques i condicions */
.pol-cond {
   display: flex;
   justify-content: justify;
   flex-direction: column;
   width: 100%;
}

.pol-cond p {
   text-align: justify;
   font-size: 16pt;
   margin-top: 3vh;
   margin-bottom: 3vh;
}

.pol-cond-titol h2 {
   text-align: center;
   font-size: 30pt;
   color: #c42020;
   margin-bottom: 3vh;
}

.pol-cond-titol {
   border-bottom: 4px solid #c42020;
}

@media screen and (max-width: 770px) {
   .pol-cond p {
      font-size: 13pt;
      margin-top: 3vh;
      margin-bottom: 3vh;
   }

   .pol-cond-titol h2 {
      font-size: 20pt;
   }
}

/* Estils spinner */
.spinner {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;
   width: 100%;
}

.spinner-excels {
   display: flex;
   justify-content: center;
   align-items: center;
   text-align: center;
   width: 100%;
   margin-bottom: 3vh;
}

.spinner-excels .spinner-border {
   margin-left: 2vh;
}

/* Estils per els botons d'edició de les taules */
.botons-edicio {
   display: flex;
   justify-content: center;
   align-items: center;
}

.botons-edicio button {
   margin-inline: 1vh;
}

/* Estils per al formulari de login */
.login-container {
   width: 100%;
   margin-inline: 5vh;
}

.login {
   background-color: #f8f9fa;
   display: flex;
   width: 100%;
   justify-content: center;
   align-items: center;
   box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
   margin-top: 6vh;
   border-radius: 50px;
}

.imatgelogin {
   display: flex;
   justify-content: center;
   position: relative;
   width: 100%;
   height: 100%;
   overflow: hidden;
}

.imatgelogin img {
   width: 100%;
   height: 100%;
   object-fit: cover;
   border-bottom-right-radius: 50px;
   border-top-right-radius: 50px;
}

.contingut-login {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 100%;
   width: 100%;
}

.login-title {
   text-align: center;
   margin-bottom: 4vh;
   font-size: 24pt;
   font-weight: bold;
   color: #333;
}

.form-label {
   color: #555;
}

.form-control {
   border-radius: 5px;
}

.login-button {
   margin-top: 20px;
}

.login-button .spinner-border {
   margin-right: 5px;
}

.campsLogin {
   margin-top: 20px;
}

.infologin {
   margin-top: 3vh;
}

.pol-us-accept {
   margin-top: 2vh;
}

@media screen and (min-width: 992px) {
   .login {
      border-radius: 50px;
   }

   .contingut-login {
      border-radius: 50px;
   }

   .imatgelogin {
      border-bottom-right-radius: 50px;
      border-top-right-radius: 50px;
   }

   /* Ajustar fonts */
   .login-container {
      font-size: 10pt;
   }

   .login-title h1 {
      font-size: 22pt;
      position: relative;
      top: 20px;
   }
}

@media screen and (min-width: 1200px) {
   .login-container {
      font-size: 12pt;
   }

   .login-title h1 {
      font-size: 25pt;
      position: normal;
      top: 0;
   }
}

@media screen and (min-width: 1400px) {
   /* Ajustar fonts */
   .login-container {
      font-size: 13pt;
   }

   .login-title h1 {
      font-size: 35pt;
      position: relative;
      top: -30px;
   }

   .campsLogin input {
      height: 50px;
      font-size: 15pt;
   }

   button.login-button.col-12.btn.btn-primary {
      height: 50px;
      font-size: 15pt;
   }
}

/* Si la pantalla es menor a 580 px d'amplada posam la imnatge avall  */
@media screen and (max-width: 991px) {
   .login {
      flex-direction: column;
      max-height: 100%;
      border-radius: 30px;
   }

   .imatgelogin {
      width: 100%;
   }

   .imatgelogin img {
      border-bottom-right-radius: 30px;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 30px;
      border-top-left-radius: 0px;
   }

   .contingut-login {
      width: 100%;
      margin-top: 5vh;
      margin-bottom: 5vh;
   }
}

button.login-button.col-12.btn.btn-primary {
   background-color: #c42020;
   border-color: #7d1e1e;
}

/* Estils per al component de logout */

.logout-info {
   display: flex;
   justify-content: center;
   align-items: center;
}

.logout-info div {
   margin-inline: 1vh;
}

/* Estils per a la secció d'usuari */
.user-section {
   margin-top: 20px;
   padding: 20px;
   background-color: #f8f9fa;
   border-radius: 10px;
   box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.user-section h1 {
   color: #333;
   margin-bottom: 10px;
}

.user-section ul {
   list-style-type: disc;
   margin-left: 20px;
   margin-bottom: 20px;
}

.user-section button {
   color: #fff;
   border: none;
   padding: 8px 16px;
   border-radius: 5px;
   cursor: pointer;
}

.user-section button:hover {
   background-color: #0056b3;
}

/* Estils footer */
.footer-container {
   display: flex;
   justify-content: center;
   flex-wrap: wrap;
}

.footer-container div {
   margin: 1vh;
}

.links-container a {
   margin-inline: 3vh;
   color: rgb(165, 165, 165);
   text-decoration: none;
}

.links-container a:hover {
   color: #c42020;
}

.footer {
   padding-top: 1rem;
   padding-bottom: 1rem;
   background-color: #212529;
   color: white;
   width: 100%;
}

/* Estils pel formulari de contacte */

#titol-contacte {
   font-family: "RobotoTitols", sans-serif;
}

#form-contacte label {
   margin-bottom: 1vh;
}

#form-contacte text {
   color: red;
}

.custom-form {
   background-color: #ffffff;
   padding: 30px;
   border-radius: 15px;
   box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1); /* Afegeix una ombra suau */
}

.custom-form .form-group {
   margin-bottom: 20px; /* Espai entre els elements del formulari */
}

.custom-form label {
   font-weight: bold;
   padding-top: 15px;
   padding-bottom: 10px;
}

/* Estils de la barra de cerca */
.navbar-nav {
   align-items: center;
}

@media screen and (min-width: 575px) {
   .nav-item::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -2px;
      width: 0%;
      height: 2px;
      background-color: #ba3131;
      transition: width 0.3s ease-in-out;
   }

   .nav-item:hover::after {
      width: 100%;
   }

   .nav-link:hover {
      color: #ba3131 !important;
      fill: #ba3131 !important;
   }

   /* Estilos específicos para la animación en los elementos de navegación */
   .nav-link,
   .nav-dropdown-item {
      display: inline-block;
      position: relative;
      cursor: pointer;
      color: #ba3131; /* Ajusta el color según sea necesario */
      fill: #c8c8c9;
   }

   .nav-link::after,
   .nav-dropdown-item::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -2px; /* Ubicación de la línea debajo del texto */
      width: 0%; /* Inicialmente invisible */
      height: 2px; /* Grosor de la línea */
      background-color: #ba3131; /* Color de la línea */
      transition: width 0.3s ease-in-out; /* Anima la expansión de la línea */
   }

   .nav-link:hover::after,
   .nav-dropdown-item:hover::after {
      width: 100%; /* La línea se expande completamente al hacer hover */
   }
}

/* Estil per barra navegacio */
nav.color-nav.navbar.navbar-expand-sm.navbar-dark.bg-dark.sticky-top {
   height: 100px;
}

/* Estils cartes */
.card {
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
   font-family: "Robototext", sans-serif;
}

div.card {
   background-color: rgb(246, 246, 246);
}

/* Estils per text salutació usuari */
span.navbar-text {
   padding-right: 30px;
}

/* Estils menú bocadillo */
.mr-auto.align-items-center.navbar-nav {
   background-color: #212529;
}
.navbar-nav {
   background-color: #212529;
}
.justify-content-end.navbar-collapse.collapse.show {
   background-color: #212529;
   text-align: center;
   padding-bottom: 20px;
}

/* Estils imatge capçalera i text */
#imatgeheader {
   display: block;
}

#textheader {
   display: none;
}

@media screen and (max-width: 575px) {
   .dropdown-menu.show {
      z-index: 1;
      position: relative !important;
   }

   .justify-content-end.navbar-collapse.collapse.show {
      padding-bottom: 30px;
   }

   #contacta {
      margin-top: 2vh;
      font-size: 14pt !important;
   }

   .navbar{
      font-size: 14pt;
   }

   #imatgeheader {
      display: none;
   }

   #textheader {
      display: block;
      color: white;
      text-align: center;
      margin: auto;
      justify-content: center;
      width: 100%;
      margin-top: 25px;
   }

   button.navbar-toggler {
      margin: 20px;
      position: absolute;
   }

   .benvinguda-usuari-container {
      position: absolute;
      height: 100%;
      top: 0;
      right: 5%;
   }

   .benvinguda-usuari-container span {
      display: none;
   }

   .svg-bombero {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      top: 26%;
  }

   .benvinguda-usuari-container svg {
      margin-left: auto !important;
      order: 2 !important;
   }

   .benvinguda-usuari-container-normal {
      display: none;
   }

  @media screen and (max-width: 575px){
   #loginweb{
      display: none;
   }
   .login-container-text{
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      top: 15%;
   }
  } 

   .links-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
   }

   .links-container a {
      margin-top: 1vh;
   }
}

.button.navbar-toggler {
   margin: 20px;
   position: absolute;
}

/* Botó formulari contacte */
#contacta {
   background-color: #c42020;
   color: #ffffff;
   padding: 8px 15px;
   font-size: 1rem;
   border: none;
   margin-inline: 2vh;
   border-radius: 0.65rem;
   cursor: pointer; /* Canvia el cursor a pointer per a indicar clicabilitat */
   box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2); /* Ombra lleugera per a profunditat */
   transition: all 0.2s ease-in-out; /* Suavitzat de transició per interaccions */
}

#contacta:hover {
   background-color: #b64e4e; /* Canvia el color de fons quan es fa hover */
}

#contacta:focus {
   outline: none; /* Elimina l'estructura quan s'està enfocant */
}

/* Estils svg usuari */
svg.bi.bi-person-fill {
   margin-right: 20px;
}

/* Estils pel calendari */
.calendari {
   height: 500px;
   margin: auto;
   margin-top: 50px;
}

.nota{
   background-color: beige;
   display: flex;
   flex-direction: column;
   width: fit-content;
   border-radius: 15px;
   max-width: 60vh;
   min-width: 30vh;
   box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
   border: 1px solid #e0e0e0;
}

.notes-container {
   margin: 2vh;
   display: flex;
   justify-content: center;
   align-items: center;
}

.editar-nota{
   padding: 2vh;
}

.editar-nota input{
   margin-bottom: 1vh;
}

.grupnotes{
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   justify-content: center;
   width: 100%;
}

.nota-data{
   padding-top: 2vh;
   text-align: center;
   padding-inline: 3vh;
   padding-bottom: 1vh;
   background-color: #c42020;
   border-bottom: 1px solid #e0e0e0;   
   border-top-left-radius: 15px;
   border-top-right-radius: 15px;
   color: white;
}

.nota-sense-data{
   padding-top: 2vh;
   text-align: center;
   padding-inline: 3vh;
   padding-bottom: 1vh;
   background-color: #7d1e1e;
   border-bottom: 1px solid #e0e0e0;   
   border-top-left-radius: 15px;
   border-top-right-radius: 15px;
   color: white;
}

.nota-text{
   padding: 3vh;
}

.nota-text p {
   max-width: 100%; /* Asegura que el max-width es relativo al contenedor padre */
   overflow-wrap: break-word; /* Rompe las palabras para evitar desbordamiento */
   word-wrap: break-word; /* Alternativa para navegadores más antiguos */
}

.botons-editar-nota{
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   min-width: fit-content;
}

.botons-editar-nota button{
   margin-top: 1vh;
   margin-inline: 0.5vh;
}

.accions {
   display: flex;
   justify-content: end;
   margin: 2vh;
}

.accions svg:last-child {
   margin-left: 10px;
}

#infonotes {
   width: 100%;
   display: flex;
   text-align: center;
   background-color: lightgreen;
   border: 1px solid green;
   border-radius: 10px;
   height: 8vh;
   justify-content: center;
   align-items: center;
}

#alertes-modals {
   color: #721C23;
   width: 100%;
   display: flex;
   text-align: center;
   background-color: #F8D7D9;
   border: 1px solid #F6C6CA;
   border-radius: 10px;
   height: 7vh;
   justify-content: center;
   align-items: center;
}

/* Estils per el menú de les dades d'usuari */
#mostrardades text, #editadadesprofessionals text {
   margin-right: 2vh;
}

#mostrardades:hover {
   background-color: #7e7e7e;
}

#editadadesprofessionals:hover {
   background-color: #7e7e7e;
}

#editadadesprofessionals, #mostrardades {
   background-color: #343434;
}

.capcalera-taula{
   text-align: center !important;
   background-color: #c42020;
   color: white;
   font-family: 'RobotoTitols', sans-serif;
}

.dadesusuari{
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
   border-radius: 15px;
}

.taula-dadesusuari {
   border-radius: 15px !important;
   width: 100%;
   background-color: white;
}

.taula-dadesusuari tr:nth-child(even) {
   background-color: #f2f2f2;
}

.taula-dadesusuari tr>td:first-child {
   background-color: #b0b0b0;
   color: white;
   font-family: 'RobotoMedium', sans-serif;
}

/* Estils taula turnos extres */

.turnos-extra{
   margin-top: 2vh;
   margin-bottom: 2vh;
   border-radius: 15px !important;
   width: 100%;
   background-color: white;
}

.turnos-extra tr:nth-child(even) {
   background-color: #f2f2f2;
}

.turnos-extra thead>tr:last-child {
   background-color: #636363;
   color: white;
   font-family: 'RobotoTitols', sans-serif;
}

.turnos-extra th{
   background-color: #c42020;
   color: white;
   font-family: 'RobotoTitols', sans-serif;
   font-weight: 2vw;
   padding: 1vh;
   font-size: 14pt;
}

.turnos-extra tbody>tr>td:nth-child(2){
   max-width: 10vh !important;
   height: fit-content;
   padding: 1vh;
}

.turnos-extra td, th{
   border:1px solid #000 !important;  
   text-align: center !important;
   padding-top: 0.5vh;
   padding-bottom: 0.5vh;
}

#esborrar-extra{
   background-color: #ffffff;
   color: rgb(31, 31, 31);
   border: 0px;
}

#esborrar-extra svg{
   width: 3vh;
   height: 3vh;
}

#link-calendari{
   display: flex;
   justify-content: center;
   align-items: center;
}


#link-calendari svg{
   margin-inline: 1vh;
}

.taula-dadesusuari td, th{
   border:1px solid #000 !important;  
   text-align: center !important;
   padding-top: 1vh;
   padding-bottom: 1vh;
}

#no-indicat{
   background-color: rgba(197, 0, 0, 0.645);
   color: white;
}

.taula-dadesusuari th{
   background-color: #c42020;
   color: white;
   font-family: 'RobotoTitols', sans-serif;
   font-weight: 2vw;
   padding: 1.5vh;
   font-size: 14pt;
}

#mostrardades, #editadadesprofessionals{
   display: flex;
   justify-content: center;
   align-items: center;
   max-width: 70vh;
}

#editadadesprofessionals {
   display: flex;
   justify-content: center;
   align-items: center;
}

#mostrarContrasenya-boto {
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: #838383;
   max-width: 8vh;
}

#mostrarContrasenya-boto:hover {
   background-color: #a6a6a6;
}

/* Estils per la pagina 404 no trobada */
.notfound {
   text-align: center;
   margin: 2vh;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.notfound h1 {
   font-size: 10vw;
   margin-bottom: 3vh;
}

.notfound {
   position: relative;
   top: 20vh;
}

@media screen and (max-width: 575px) {
   .notfound {
      position: relative;
      top: 30vh;
   }
}

#boto-notfound {
   display: flex;
   justify-content: center;
   align-items: center;
   margin-top: 3vh;
   max-width: 50vw;
}

#boto-notfound text {
   margin-inline-end: 1vw;
}

/* Estils calendari generals */

.rbc-toolbar-label,
.rbc-header {
   /* Tot en majuscula */
   text-transform: uppercase;
}

.rbc-toolbar-label {
   font-size: 18pt !important;
   font-family: "RobotoMedium", sans-serif;
}

.rbc-toolbar {
   margin-bottom: 4vh !important;
}

.rbc-toolbar button {
   padding-inline: 20px !important;
   padding-top: 10px !important;
   padding-bottom: 10px !important;
}

.rbc-header {
   background-color: #c42020;
   color: #fff;
   font-family: "RobotoMedium", sans-serif;
}

table.rbc-agenda-table {
   color: white;
}

/* Estils calendari responsive */

@media (max-width: 992px) {
   .calendari {
      margin-top: 0;
      height: 90vh;
   }
   .rbc-header {
      padding: 2px;
      font-size: 10px;
   }
   .rbc-event-content {
      font-size: 10px;
   }
   .rbc-row-segment {
      padding: 2px;
   }
   .rbc-today {
      font-size: 10px;
   }
   .rbc-event {
      font-size: 10px;
   }
   .rbc-show-more {
      font-size: 10px;
   }
   .rbc-btn-group {
      display: flex !important;
      flex-wrap: wrap !important;
      align-items: center !important;
      justify-content: center !important;
   }
   .rbc-toolbar button {
      padding: 10px !important;
   }

   .rbc-toolbar-label {
      flex-grow: 0 !important;
      margin: 10px !important;
      font-size: 14pt !important;
   }

   .rbc-toolbar {
      flex-flow: column;
      justify-content: center;
      align-items: center;
      height: 23vh;
   }
}

@media screen and (max-width: 992px) {
   .rbc-btn-group {
      flex-wrap: nowrap !important;
      width: 100%;
      order: 2; /* Coloca .rbc-btn-group después de .rbc-toolbar-label */
   }

   .rbc-btn-group button {
      margin-top: 3vh !important;
      width: 100% !important;
   }

   .rbc-toolbar-label {
      order: 1; /* Esto pone .rbc-toolbar-label en la primera posición del flujo flex */
      text-align: center; /* Centra el texto dentro de su espacio */
      font-size: 18pt !important;
   }

   .rbc-toolbar {
      height: auto;
      display: flex; /* Asegura que los elementos estén en un flujo de flex */
      flex-direction: column; /* O 'column', según lo necesites */
   }
}

/* Estils barra cerca cruds */
.capcalera-cruds {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 40px;
}

.capcalera-cruds button {
   margin-inline: 1vh;
   text-align: center;
   width: 30%;
   height: 100%;
}

.capcalera-cruds input {
   width: 70%;
}

.titularTaules {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   width: 100%;
}

.capcalera-cruds-visibilitat{
   display: flex;
   justify-content: center;
   align-items: center;
   height: 40px;
   width: 60%;
}

@media  screen and (max-width: 1015px) {
   .capcalera-cruds-visibilitat {
      width: 100%;
   }
}

.titularTaules .capcalera-cruds {
   width: 60%;
}

.titularTaules .titolTaula {
   width: 40%;
}

.boto-afegir-container {
   align-items: center;
   justify-content: center;
}

.boto-afegir-container svg {
   margin-right: 1vh;
}

@media screen and (max-width: 992px) {
   .titularTaules {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
   }
   .titularTaules .capcalera-cruds {
      width: 100%;
      height: fit-content;
   }

   .capcalera-cruds button {
      width: 90%;
   }

   .titularTaules .titolTaula {
      width: 100%;
      text-align: center;
   }
}

/* Estils pel component ajuda */

.help-container {
   position: relative;
   height: 100%;
   width: 100%;
   padding: 20px;
   border-radius: 8px;
   border: 1px solid #ccc;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
   z-index: 1;
}

/* APlicar un fons amb una opacitat del 20% */
.admin-container::before {
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-image: url(../images/fondoapp5.webp);
   background-size: contain;
   background-repeat: round;
   opacity: 0.1;
   z-index: -1;
   border-radius: 8px;
}

.admin-container {
   position: relative;
   height: 100%;
   min-height: 100vh;
   width: 100%;
   padding: 20px;
   border-radius: 8px;
   border: 1px solid #ccc;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
   z-index: 1;
}

/* APlicar un fons amb una opacitat del 20% */
.help-container::before {
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-image: url(../images/fondohangar.jpg);
   background-size: contain;
   background-repeat: round;
   opacity: 0.1;
   z-index: -1;
   border-radius: 8px;
}

.help-container p {
   color: #111111;
   text-align: justify;
   font-size: 16pt;
   margin-top: 3vh;
   margin-bottom: 3vh;
}

.ajuda-peu p {
   text-align: center;
   color: #7d1e1e;
}

.ajuda-subtitol {
   margin-top: 6vh;
   margin-left: 2vh;
   margin-right: 2vh;
}

.ajuda-titol {
   text-align: center;
   margin-bottom: 3vh;
   border-bottom: 4px solid #c42020;
}

.ajuda-titol h2 {
   color: #c42020;
   font-size: 30pt;
   margin-bottom: 3vh;
}

.ajuda-contingut {
   text-align: justify;
   font-size: 16pt;
   margin-top: 6vh;
   margin-bottom: 6vh;
   margin-left: 6vh;
   margin-right: 6vh;
}

@media screen and (max-width: 770px) {
   .ajuda-titol h2 {
      font-size: 20pt;
   }

   .ajuda-contingut {
      font-size: 13pt;
      margin-top: 3vh;
      margin-bottom: 3vh;
      margin-left: 0vh;
      margin-right: 0vh;
   }

   .help-container p {
      font-size: 13pt;
   }
}

/* Estils menú excels */

.excel-container-body {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: row;
   width: 100%;
   height: fit-content;
}

.excel-file {
   width: 100%;
}

.botons-excels button {
   width: 100%;
   max-width: fit-content;
   margin-inline: 1vh;
}

.botons-titol h2{
   margin: 0;
   margin-inline: 2vh;
}

.botons-excels {
   width: 100%;
   max-width: fit-content;
   height: fit-content;
   display: flex;
   align-items: flex-end;
}

@media screen and (max-width: 768px) {
   .excel-container-body {
      flex-direction: column;
      height: 100%;
   }

   .botons-excels {
      margin-top: 2vh;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      width: 100%;
   }

   .botons-excels button {
      width: 100%;
      max-width: 100%;
      margin-inline: 0;
   }

   .botons-excels button:nth-of-type(2) {
      margin-top: 2vh;
   }

   .botons-titol{
      width: 100%;
      text-align: center;
      margin-bottom: 2vh;
   }
}

/* Estils botó mostrar dades personals */

.user-section {
   position: relative;
   height: 100%;
   width: 100%;
   padding: 20px;
   border-radius: 8px;
   border: 1px solid #ccc;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
   z-index: 1;
}

.user-section::before {
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-image: url(../images/fondoapp3.webp);
   background-size: cover;
   background-repeat: round;
   opacity: 0.1;
   z-index: -1;
   border-radius: 8px;
}

.dades-usuari-titol {
   border-bottom: 2px solid #333;
   padding: 2vh;
   display: flex;
   flex-direction: row;
}

@media screen and (max-width: 992px) {
   .dades-usuari-titol{
      display: flex;
      flex-direction: column;
   }
}

.dadesprofessionals-titol {
   color: #c42020;
   border-bottom: 2px solid #c42020;
   padding: 2vh;
   margin-bottom: 4vh;
}

.dades-titol {
   width: 100%;
}

.boto-anar-calendari {
   min-width: fit-content;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
}

.form-dades-usuari {
   margin: 3vh;
   width: 100%;
}

.boto-mostrar-dades-personals {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   flex-direction: column;
}

.boto-mostrar-dades-personals button {
   width: 100%;
   margin-inline: 1vh;
}

.botons-dades {
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   margin-top: 3vh;
}

@media screen and (max-width: 768px) {
   .botons-dades{
      flex-direction: column;
      margin-top: 1vh;
   }

   .botons-dades button{
      margin-top: 2vh;
   }

   .dades-usuari-titol{
      padding: 0;
      padding-top: 2vh;
      padding-bottom: 2vh;
   }
}


/* Estils turnos calendari visibles */

@media screen and (max-width: 575px) {
   .rbc-event.rbc-event-allday {
      height: 4vh;  
   }

  .rbc-event-content {
      font-size: 10pt;
   }
}

/* Estils per afegir notes */

.afegirnotes {
   margin-top: 2vh;;
}

/* Estils captcha */

.captcha {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
}


#titolturno{
   text-align: center;
   margin-top: 2vh;
   margin-bottom: 2vh;
}

/* Estils confirmacions */

@media  screen and (max-width: 575px) {

   .alert.alert-success {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
   }
   
   .alert.alert-danger{
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
   }
   
}

/* Estils per la data de darrera actualització*/

.turnos-upd {
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   margin-top: 2vh;
}

@media screen and (max-width: 575px) {
   .turnos-upd {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
   }
   
}

.turnos-upd p:first-child {
   margin-inline: 1vh;
   font-family: 'RobotoMedium', sans-serif;
}

/* Consultar turnos d'un dia concret */

.consultar-turnos {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: row;
   width: 100%;
}

.consultar-turnos-title {
   display: flex;
   justify-content: left;
   flex-direction: column;
   text-align: left;
   width: inherit;
}

.consultar-turnos-boto {
   display: flex;
   justify-content: right;
   align-items: center;
   flex-direction: row;
   width: 70%;
}

.consultar-turnos-boto svg{
   margin-right: 1vh;
}

.form-cercar-turnos-container{
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   width: 100%;
}

.form-cercar-turnos {
   display: flex;
   flex-direction: row;
   width: 100%;
}

.camps-cercar-dia {
   display: flex;
   justify-content: center;
   flex-direction: column;
   width: 100%;
}

.camps-cercar-dia label{
   text-align: left;
}

.botons-cercar-dia {
   display: flex;
   justify-content: center;
   align-items: end;
   width: fit-content;
   margin-left: 1vh;
}

@media  screen and (max-width: 768px) {
   .consultar-turnos {
      flex-direction: column;
   }

   .consultar-turnos-title {
      width: 100%;
   }

   .consultar-turnos-boto {
      width: 100%;
      justify-content: center;
   }

   .consultar-turnos-boto button {
      margin-top: 1vh;
      width: 100%;
   }

}

/* Estils botons usuari */
#boto-mostrar-turnos svg, #boto-ocultar-turnos svg{
   margin-right: 1vh;
}

@media screen and (max-width: 992px) {
   .boto-anar-calendari{
      flex-direction: column;
      margin-top: 1vh;
   }

   #boto-mostrar-turnos, #boto-ocultar-turnos{
      width: 100%;
   }

   .linkinot{
      margin-left: 0;
      margin-top: 1vh;
      width: 100% !important;
   }
   
   #link-calendari{
      width: 100%;
   }
}

/* Estils fondo alerts */

.fondo-alert {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.5);
   z-index: 9998;
}

.alert-wrapper {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 9999;
   opacity: 1.0; /* Asegura que el alert no sea transparente */
}

/* Estils boto notificacions */
#notificacions-desactivades-boto{
   margin-left: 1vh;
}

.linkinot{
   display: flex;
   flex-direction: row;
   width: fit-content;
   justify-content: center;
   align-items: center;
   margin-left: 1vh;
}